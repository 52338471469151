import commonHelper from '@/app/utility/common.helper.utility';
import ModulesList from '../responsibility/searchModule';
import Flexfield from '../flexfield';
import FlexfieldMaster from '../flexfield';
import CodeCombinationDetails from './addCodeCobination';
import appStrings from '@/app/utility/string.utility';
import { ModelSelect } from 'vue-search-select';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'CodeCombination',
  components: {
    ModulesList,
    Flexfield,
    FlexfieldMaster,
    CodeCombinationDetails,
    ModelSelect
  },
  watch: {
    'modules.text': function() {
      this.getFlexfieldMasterLov();
    }
  },
  data() {
    return {
      payload: null,
      loader: false,
      unsubscribe: null,
      showModal: false,
      showCodeComDtlModalFlag: false,
      showValueSetModal: false,
      vsetCode: null,
      totalRows: null,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      childComp: null,
      legalEntity: {
        value: null,
        text: null
      },
      modules: {
        value: null,
        text: null
      },
      flexfieldMaster: {
        value: null,
        text: null
      },
      flexfieldMasterList: [],
      flexfield: {
        value: null,
        text: null
      },
      codeCombination: null,
      active: true,
      tab: null,
      codeCombLineDetail: null,
      codeCombinationList: [],
      codeCombinationFields: [],
      segmentOne: {
        value: null,
        text: null
      },
      segmentTwo: {
        value: null,
        text: null
      },
      codeCombDetails: [],
      codeCombValuesets: null,

    };
  },
  validations: {
    legalEntity: {
      text: {
        required
      }
    },
    flexfield: {
      text: {
        required
      }
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.codeCombLineDetail = null;
          this.openCloseCodeCombDtlModal(true);
        }
        if (
          actionName === 'download' &&
          !this.showCodeComDtlModalFlag &&
          !this.showModal
        ) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.total;
          this.downloadExcel.downloadData(
            downloadpayload,
            'codeCombination/getCodeCombinationList',
            'code-combination',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    setChildName(tab) {
      //method for child modal name
      if (tab === 'ModulesList') {
        return (this.childName = 'Modules List');
      } else {
        return (this.childName = 'Flexfield');
      }
    },
    getCodeCombinationList() {
      if (
        (this.segmentOne.text && this.segmentTwo.text) ||
        this.codeCombination
      ) {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.payload = {
            _page: this.currentPage - 1,
            _limit: this.perPage,
            flexfield_id: this.flexfield.value,
            le_id: this.legalEntity.value,
            active: this.active,
            concatenated_segment: this.codeCombination,
            segment1: this.segmentOne.text,
            segment2: this.segmentTwo.text
          };
          this.loader = true;
          this.$store
            .dispatch('codeCombination/getCodeCombinationList', this.payload)
            .then(response => {
              this.loader = false;
              if (response.status === 200) {
                this.codeCombinationFields = [];
                const codeComFields = response.data.data.columns;
                codeComFields.forEach(elem => {
                  this.codeCombinationFields.push({
                    key: elem,
                    class:
                      elem.search('id') < 0 && elem.search('mean') < 0
                        ? ''
                        : 'd-none'
                  });
                });
                this.codeCombinationList = response.data.data.dynamic_data;
                this.total = response.data.data.dynamic_data.length;
              }
            })
            .catch(() => {
              this.loader = false;
            });
        }
      } else {
        alert(
          'Please Select Segment One and Segment Two Together or Concatenated Segment'
        );
      }
    },
    getFlexfieldMasterLov() {
      const module_id = this.modules.value;
      this.loader = true;
      this.$store
        .dispatch('flexfield/getFlexfieldMasterLovList', module_id)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.flexfield_master_name,
                text: type.flexfield_master_name
              };
            });
            this.flexfieldMasterList = getValueAndText;
            if (getValueAndText && getValueAndText.length == 1) {
              this.flexfieldMaster = getValueAndText[0];
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearFilters() {
      this.legalEntity = {
        value: null,
        text: null
      };
      this.modules = {
        value: null,
        text: null
      };
      this.flexfieldMaster = {
        value: null,
        text: null
      };
      this.flexfield = {
        value: null,
        text: null
      };
      this.codeCombination = null;
      this.active = true;
      this.segmentOne = {
        value: null,
        text: null
      };
      this.segmentTwo = {
        value: null,
        text: null
      };
    },
    rowSelected(item) {
      this.codeCombLineDetail = item;
      this.openCloseCodeCombDtlModal(true);
    },
    openCloseCodeCombDtlModal(flag) {
      this.showCodeComDtlModalFlag = flag;
    },
    openShowModal(flag, component) {
      this.showModal = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    selectedRow(moduleData) {
      if (this.tab === 'ModulesList') {
        this.modules = {
          value: moduleData.module_id,
          text: moduleData.module_name
        };
      } else if (this.tab === 'FlexfieldMaster') {
        this.flexfieldMaster = {
          value: moduleData.flexfield_master_id,
          text: moduleData.flexfield_master_name
        };
      } else if (this.tab === 'Flexfield') {
        this.flexfield = {
          value: moduleData.flexfield_id,
          text: moduleData.flexfield_name
        };
        this.getValueSetForCodeCombination();
      }
      this.showModal = false;
    },
    checkSegmentBasedValueSet(segmentCode, segment) {
      this.segmentCode = segmentCode;
      this.segment = segment;
      this.vsetCodeObj = this.codeCombValuesets.find(key => {
        return segment === key.segment;
      });

      this.openValueSetModal(this.vsetCodeObj.valueset_name);
    },
    getValueSetForCodeCombination() {
      const payload = {
        flexfield_id: this.flexfield.value
      };
      this.loader = true;
      this.$store
        .dispatch('codeCombination/getValueSetForCodeCombination', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.codeCombValuesets = response.data.data;
          }
        });
    },
    openValueSetOnSegment(vsetCode) {
      switch (vsetCode.segment) {
        case 'SEGMENT1':
          this.valueSetNameOne = vsetCode.valueset_name;
          break;
        case 'SEGMENT2':
          this.valueSetNameTwo = vsetCode.valueset_name;
          break;
      }
    },
    // checkSegmentBasedValueset(segementCode) {
    //   this.vsetCodeObj = this.codeCombValuesets.find(key => {
    //     return segementCode === key.segment_code;
    //   });
    //   this.openValueSetModal(this.vsetCodeObj.valueset_name);
    // },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (this.vsetCode === this.vsetCodeObj.valueset_name) {
        if (this.segment === 'SEGMENT1') {
          this.segmentOne.text = item.value_code;
          this.segmentOne.value = item.value_code;
        } else {
          this.segmentTwo.text = item.value_code;
          this.segmentTwo.value = item.value_code;
        }
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.legalEntity.value) {
        this.legalEntity = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.modules.value) {
        this.modules = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.flexfield.value) {
        this.flexfield = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.segmentOne.value) {
        this.segmentOne = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.segmentTwo.value) {
        this.segmentTwo = {
          text: null,
          value: null
        };
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
