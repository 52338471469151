import { ModelSelect } from 'vue-search-select';
import ModulesList from '../../responsibility/searchModule';
import Flexfield from '../../flexfield';
import FlexfieldMaster from '../../flexfield';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'CodeCombinationDetails',
  components: {
    ModelSelect,
    ModulesList,
    Flexfield,
    FlexfieldMaster
  },
  watch: {
    'modules.text': function() {
      this.getFlexfieldMasterLov();
    }
  },
  props: ['codeCombLineDetail'],
  data() {
    return {
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      editMode: false,
      showModal: false,
      childName: null,
      tab: null,
      showValueSetModal: false,
      flexfield_id: null,
      flexfield_name: null,
      key_flex_id: null,
      le_id: null,
      modules: {
        value: null,
        text: null
      },
      flexfieldMaster: {
        value: null,
        text: null
      },
      flexfieldMasterList: [],
      tempCodeCombDetails: {},
      codeCombDetails: [],
      codeCombDetailsFields: []
    };
  },
  mounted() {
    if (this.codeCombLineDetail) {
      this.getCodeCombLineDetail(this.codeCombLineDetail);
      this.getCodeCombColumns();
      this.getCodeCombDtlsByKeyFlexId();
    } else {
      this.editMode = true;
    }

    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditCodeCombSegments();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    addEditCodeCombSegments() {
      const details = this.codeCombDetails.map(data => {
        return {
          SEGMENT1: data.SEGMENT1,
          SEGMENT2: data.SEGMENT2,
          SEGMENT3: data.SEGMENT3,
          SEGMENT4: data.SEGMENT4 ? data.SEGMENT4 : null,
          SEGMENT5: data.SEGMENT5 ? data.SEGMENT5 : null,
          SEGMENT6: data.SEGMENT6 ? data.SEGMENT6 : null,
          SEGMENT7: data.SEGMENT7 ? data.SEGMENT7 : null,
          active: data.active, // mandatory
          key_flex_id: data.key_flex_id // for new send 0 and for edit send key_flex_id of grid
        };
      });
      const payload = {
        flexfield_id: this.flexfield_id,
        segment_details: details ? details : null
      };
      this.loader = true;
      this.$store
        .dispatch('codeCombination/addEditCodeCombSegments', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = resp.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = resp.response.data.message;
          }
        })
        .catch(() => {
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
          this.loader = false;
        });
    },
    getCodeCombLineDetail(lineDetail) {
      this.flexfield_id = lineDetail.flexfield_id;
      this.key_flex_id = lineDetail.key_flex_id;
      this.le_id = lineDetail.le_id;
    },
    getCodeCombColumns() {
      const flexfield_id = this.flexfield_id;
      this.$store
        .dispatch('codeCombination/getCodeCombColumns', flexfield_id)
        .then(response => {
          if (response.status === 200) {
            this.codeCombDetailsFields = [];
            const codeCombFields = response.data.data;
            this.codeCombValuesets = codeCombFields;
            codeCombFields.forEach(elem => {
              this.codeCombDetailsFields.push({
                key: elem.segment_code,
                label: elem.column_display
              });
              this.codeCombDetailsFields.push({
                key: elem.segment,
                class: 'd-none'
              });
            });
            const rowManipulationFields = [
              {
                key: 'key_flex_id',
                class: 'd-none'
              },
              {
                key: 'active'
              },
              {
                key: 'add'
              },
              {
                key: 'remove'
              }
            ];
            this.codeCombDetailsFields = this.codeCombDetailsFields.concat(
              rowManipulationFields
            );
            if (!this.key_flex_id) {
              const tempCodeCombData = this.insertRowForCodeCombDetails();
              this.tempCodeCombDetails = tempCodeCombData;
            }
          }
        });
    },
    insertRowForCodeCombDetails() {
      let dummyObj = {};
      this.codeCombDetailsFields.forEach(elem => {
        if (
          elem.key != 'add' &&
          elem.key != 'remove' &&
          elem.key != 'key_flex_id' &&
          elem.key != 'active'
        ) {
          dummyObj[elem.key] = null;
        }
      });
      dummyObj['key_flex_id'] = 0;
      dummyObj['active'] = true;
      this.codeCombDetails.push(dummyObj);
      return dummyObj;
    },
    getCodeCombDtlsByKeyFlexId() {
      const payload = {
        keyFlexId: this.key_flex_id,
        request_params: {
          flexfield_id: this.flexfield_id,
          le_id: this.le_id
        }
      };
      this.$store
        .dispatch('codeCombination/getCodeCombDtlsByKeyFlexId', payload)
        .then(response => {
          if (response.status === 200) {
            const details = response.data.data;
            this.modules = {
              text: details.module_name,
              value: details.module_id
            };
            this.flexfieldMaster = {
              value: details.flexfield_master_id,
              text: details.flexfield_master_name
            };
            this.flexfield_id = details.flexfield_id;
            this.flexfield_name = details.flexfield_name;
            this.codeCombDetails = response.data.data.segment_details;
          }
        });
    },
    getFlexfieldMasterLov() {
      const module_id = this.modules.value;
      this.loader = true;
      this.$store
        .dispatch('flexfield/getFlexfieldMasterLovList', module_id)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.flexfield_master_name,
                text: type.flexfield_master_name
              };
            });
            this.flexfieldMasterList = getValueAndText;
            if (getValueAndText && getValueAndText.length == 1) {
              this.flexfieldMaster = getValueAndText[0];
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openShowModal(flag, component) {
      this.showModal = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    selectedRow(moduleData) {
      if (this.tab === 'ModulesList') {
        this.modules = {
          value: moduleData.module_id,
          text: moduleData.module_name
        };
      } else if (this.tab === 'FlexfieldMaster') {
        this.flexfieldMaster = {
          value: moduleData.flexfield_master_id,
          text: moduleData.flexfield_master_name
        };
      } else if (this.tab === 'Flexfield') {
        this.flexfield_id = moduleData.flexfield_id;
        this.flexfield_name = moduleData.flexfield_name;
        this.getCodeCombColumns();
      }
      this.showModal = false;
    },
    setChildName(tab) {
      //method for child modal name
      if (tab === 'ModulesList') {
        return (this.childName = 'Modules List');
      } else {
        return (this.childName = 'Flexfield');
      }
    },
    checkSegmentBasedValueset(segementCode, segment, index) {
      this.segmentCode = segementCode;
      this.segment = segment;
      this.codeCombIndex = index;
      this.vsetCodeObj = this.codeCombValuesets.find(key => {
        return segementCode === key.segment_code;
      });
      this.openValueSetModal(this.vsetCodeObj.valueset_name);
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      this.codeCombDetails[this.codeCombIndex][this.segmentCode] =
        item.value_code;
      this.codeCombDetails[this.codeCombIndex][this.segment] = item.value_code;
      this.codeCombIndex = null;
      this.segementCode = null;
      this.segment = null;
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    addNewRow() {
      this.insertRowForCodeCombDetails();
    },
    removeRow(index) {
      this.codeCombDetails.splice(index, 1);
    },
    clearVsetValues(segmentCo, segment, index) {
      if (segmentCo === 'SEGMENT1_CODE') {
        this.codeCombDetails[index][segmentCo] = null;
        this.codeCombDetails[index][segment] = null;
      } else if (segmentCo === 'SEGMENT2_CODE') {
        this.codeCombDetails[index][segmentCo] = null;
        this.codeCombDetails[index][segment] = null;
      } else if (segmentCo === 'SEGMENT3_CODE') {
        this.codeCombDetails[index][segmentCo] = null;
        this.codeCombDetails[index][segment] = null;
      } else if (segmentCo === 'SEGMENT4_CODE') {
        this.codeCombDetails[index][segmentCo] = null;
        this.codeCombDetails[index][segment] = null;
      } else if (segmentCo === 'SEGMENT5_CODE') {
        this.codeCombDetails[index][segmentCo] = null;
        this.codeCombDetails[index][segment] = null;
      } else if (segmentCo === 'SEGMENT6_CODE') {
        this.codeCombDetails[index][segmentCo] = null;
        this.codeCombDetails[index][segment] = null;
      } else if (segmentCo === 'SEGMENT7_CODE') {
        this.codeCombDetails[index][segmentCo] = null;
        this.codeCombDetails[index][segment] = null;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
